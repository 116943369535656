import React, { useEffect, useState, useRef } from "react";
import ThreeJSModule from './modules/ThreeJSModule';
import { Box, Grow, styled } from '@mui/material';
import PrimaryButton from "../Utility/PrimaryButton";
import LoadingComp from "../LoadComp";


function ARjsComp(props) {

    let threeJSModule

    const container = useRef(null);
    const [needScan, setNeedScan] = useState(false);
    const [loading, setloading] = useState("done")


    const sartAR = async () => {

    }

    const learnMoreHandler = () => {
        props.setArjs(false)
        props.setSlideShow(true)
    }

    const target = "../assets/targets/targets.mind"



    useEffect(() => {
        threeJSModule = new ThreeJSModule({
            container: container.current,
            appData: props.appData,
            target: target,
            setNeedScan,
            setloading,
        })
        return () => {
            threeJSModule.stop()
        };
    }, [])

    return (
        <Box
            sx={{
                top: "0",
                width: "100vw",
                height: "100vh",
                position: "absolute",
                overflow: "hidden",
                zIndex: "1000",
                backgroundColor: "white"
            }}
        >




            <Box
                component='div'
                sx={{
                    width: "100vw",
                    height: "100vh",
                    position: "absolute",
                    overflow: "hidden",
                }}
                ref={container}
                id="container"
            >
            </Box>

            {loading === "done" &&
                <PrimaryButton
                    onClick={learnMoreHandler}
                    sx={{
                        position: "absolute",
                        bottom: "30px",
                        width: '200px',
                        left: "calc(50% - 100px)",
                        zIndex: "2000"
                    }}
                >Learn More</PrimaryButton>

            }

            {loading != "done" &&
                <LoadingComp loading={loading} />

            }


            {needScan && (
                <Box
                    id="scan-container"
                    sx={{
                        color: "white",
                        width: " 100vw",
                        height: " 100%",
                        position: "absolute",
                        top: "0",
                        gap: "10px",
                        zIndex: "500",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                    }}>

                    <Box
                        component="img"
                        alt="wala xr shirt scan camera"
                        src="../assets/images/camera.svg"
                        sx={{
                            position: "absolute",
                            top: "calc(50% - 150px)",
                            height: "200px",
                            zIndex: "100",
                            animation: "2s infinite alternate cameraMove",
                        }}
                    >

                    </Box>
                    <Box component="img"
                        src={`../assets/images/irontabletopper 1.png`}
                        alt="xr shirt arcode scan"
                        sx={{
                            opacity: "0.5",
                            maxWidth: "300px"
                        }}
                    >
                    </Box>
                </Box>
            )}


        </Box>

    )

}

export default ARjsComp;
