
import React, { useEffect, useState, useRef } from "react";
import { Box, Grow, styled } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import CloseButton from "../Utility/CloseButton";

const CustomLoading = styled(LinearProgress)((props) => ({
    height: 10,
    width: "70%",
    maxWidth: "250px",
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#EDEDED",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#FD8B1E',
    },
}));

function ScheduleIframeComp(props) {


    return (
        <Grow
            in={props.link}
        >
            <Box sx={{
                position: 'fixed',
                top: '0',
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: "40px",
                backgroundColor: '#585858b0',
                transition: 'opacity 1s',
                opacity: '1',
                zIndex: '1400',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                fontWeight: 600,
            }}>

                <Box

                    sx={{
                        backgroundColor: 'white',
                        display: "flex",
                        maxWidth:"700px",
                        width: "90%",
                        height: "70%",
                        padding: "15px",
                        borderRadius: "5px",
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        gap: "20px",
                        position: "relative",
                        paddingTop: "80px"
                    }}
                >

                    <Box
                        component='img'
                        src={"../assets/images/eye.png"}
                        sx={{
                            fontSize: '2rem',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: "90px",
                            position: "absolute",
                            top: "0",
                            borderRadius: "100px",
                            transform: "translate(0, -50%)"
                        }}>
                    </Box>

                    <CloseButton

                        onClick={props.onClose}
                        sx={{
                            position: 'absolute',
                            right: "0",
                            top: "0",
                            margin: "10px"
                        }}
                    />

                    <iframe
                        style={{
                            width: "100%",
                            height: "100%",
                            border:"none"
                        }}

                        src='https://calendly.com/ironview360' title="IronView360 schdule"></iframe>

                </Box>

            </Box>
        </Grow>

    );
}

export default ScheduleIframeComp;


