import CloseIcon from '@mui/icons-material/Close';
import { styled } from "@mui/material/styles";

const CloseButton = styled(CloseIcon)((props) => ({
  color:"#FD8B1E",
  border:"2px solid #FD8B1E",
  padding:"5px",
  borderRadius:"50px",
  cursor:"pointer"
}));

export default CloseButton


