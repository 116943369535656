import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const PrimaryButton = styled(Button)((props) => ({
  height: "45px",
  color: "white",
  'fontSize': '0.8rem',
  'fontFamily': 'Roboto',
  fontFamily: "'Inter', sans-serif",
  'display': 'flex',
  'width': '70%',
  maxWidth: "350px",
  'borderRadius': '60px',
  'padding': '4px 0',
  'gap': '10px',
  'animation': '3s infinite pop',
  backgroundColor: "#FD8B1E",
  cursor: "pointer",
  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.10)",
  fontWeight: 600,
  transitionProperty: "transform",
  transitionDuration: " 0.2s",
  '&:hover': {
    backgroundColor: "#FD8B1E",
    transform: "scale(1.1)",
  },
}));

export default PrimaryButton


