
import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, Button, styled, SvgIcon } from '@mui/material';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import MailIcon from '@mui/icons-material/Mail';


import PrimaryButton from "../Utility/PrimaryButton";

import SecondaryButton from "../Utility/SecondaryButton";

import Slider from "./Slider";

import CloseButton from "../Utility/CloseButton";

import Three360Comp from "./Three360Comp";
import { B } from "mind-ar/dist/controller-495b585f";


function SlideShowComp(props) {

    const [slideWidth, setSlideWidth] = useState(50)

    const redireTo = (to) => {
        window.open(to, '_blank')
    }



    useEffect(() => {

    }, [])

    return (
        <React.Fragment>

            <Box sx={{
                position: 'absolute',
                top: '0',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around',
                backgroundColor: 'white',
                transition: 'opacity 1s',
                opacity: '1',
                zIndex: '1000',
                backgroundSize: 'cover',
                backgroundPosition: 'cover',
                fontWeight: 600,
                backgroundImage: "url('../assets/images/Construction-site.jpg')",
                // background:"linear-gradient(0deg, rgba(255 255 255 / 90%), rgba(255 255 255 / 90%)), url(./assets/images/Construction-site.jpg)",
                "& :before": {
                    content: "' '",
                    position: "absolute",
                    left: "0",
                    right: "0",
                    top: "0",
                    bottom: "0",
                    zIndex: "-100",
                    background: "rgb(255 255 255 / 21%)",
                }
            }}>



                <Box
                    sx={{
                        height: "60px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >

                    <CloseButton
                        onClick={props.onClose}
                        sx={{
                            right: "0",
                            top: "0",
                            alignSelf: "flex-end",
                            margin: "10px"
                        }}
                    />

                </Box>
                <Box
                    component='img'
                    src={"../assets/images/Black-Logo-horizontal.svg"}
                    sx={{
                        fontSize: '2rem',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: "350px",
                        marginBottom: "40px",
                    }}>
                </Box>


                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: "relative",
                    fontWeight: 600,
                }}>



                    <Box
                        sx={{
                            width: "300px",
                            height: "300px",
                            position: "relative",
                        }}
                    >


                        <Box
                            sx={{
                                position: "absolute",
                                left: `${slideWidth * 3}px`,
                                top: "-20px",
                                zIndex: "200",
                                transform: "translate(-50%, 0)"
                            }}
                        >
                            <Box
                                sx={{
                                    width: "10px",
                                    height: "10px",
                                    border: "3px solid #FD8B1E",
                                    borderRadius: "100px"
                                }}
                            />

                            <Box
                                sx={{

                                    width: "5px",
                                    height: "310px",
                                    backgroundColor: "#FD8B1E",
                                    marginLeft: "5px",
                                }}
                            >

                            </Box>

                        </Box>

                        <Box
                            sx={{
                                position: "absolute",
                                left: "0",
                                top: "0",
                                height: '100%',
                                width: `${slideWidth * 3}px`,
                                height: "100%",
                                backgroundImage: "url('../assets/images/slides/2.png')",
                                backgroundPosition: "left",
                                backgroundSize: "cover",
                            }}

                        />
                        <Box
                            sx={{
                                position: "absolute",
                                left: "0",
                                top: "0",
                                height: '100%',
                                width: "100%",
                                height: "100%",
                                zIndex: "-10",
                                backgroundImage: "url('../assets/images/slides/8.png')",
                                backgroundPosition: "left",
                                backgroundSize: "cover",
                            }}

                        />

                    </Box>

                    <Slider setSlideWidth={setSlideWidth} />

                </Box>


                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: "100%",
                        margin: "20px",
                        padding: "30px 0",
                        backgroundColor: "#E9E9E9"
                    }}

                >
                    <Typography
                        sx={{
                            textAlign: "center",
                            color: " #000",
                            fontDamily: "Inter",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "normal",
                            textTransform: "capitalize",
                            width: "260px",
                            padding: "20px 0"
                        }}
                    >
                        360 As-built and Progress Documentation
                    </Typography>
                    <Three360Comp />
                </Box>

                <Box
                    sx={{
                        padding: "20px",
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            textAlign: "center",
                            color: " #000",
                            fontDamily: "Inter",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "normal",
                            textTransform: "capitalize",
                            width: "260px",
                            padding: "20px 0"
                        }}
                    >
                        The Power of 3-Clicks
                    </Typography>
                    <iframe width="360" height="360" src="https://www.youtube.com/embed/8-FGs2H_lgo?si=rKeh3fVp5UCebhRs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: "15px",
                        width: "300px",
                        marginTop: "20px"
                    }}
                >

                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: "20px",

                        }}
                    >
                        <SecondaryButton
                            onClick={() => {
                                // window.scrollTo({ top: 0, behavior: 'smooth' });
                                props.setScheduleIframeLink(true)
                                // redireTo()
                            }}
                        >
                            <CalendarMonthOutlinedIcon
                                sx={{
                                    color: "#FD8B1E",
                                }}
                            />
                            Schedule
                        </SecondaryButton>
                        <SecondaryButton
                            onClick={() => {
                                // window.scrollTo({ top: 0, behavior: 'smooth' });
                                props.setContactIframeLink(true)
                                // redireTo('https://www.ironview360.com/contact/')
                            }}
                        >
                            <MailIcon
                                sx={{
                                    color: "#FD8B1E",
                                }}
                            />
                            Contact
                        </SecondaryButton>
                    </Box>

                    <Typography
                        onClick={() => {
                            // redireTo("https://xrgoods.co/privacy-policy")
                            props.setPrivacyMode(true)
                        }}
                        sx={{
                            color: "black",
                            cursor: "pointer"
                        }}
                    >Privacy Policy</Typography>
                    <Box
                        sx={{
                            height: "20px"
                        }}
                    ></Box>
                </Box>


            </Box>




        </React.Fragment >
    );
}

export default SlideShowComp;

