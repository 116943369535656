
import React, { useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';

import { Box, Typography, Button, styled, IconButton } from '@mui/material';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import AppVersion from "../../package.json"
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import MailIcon from '@mui/icons-material/Mail';
import AssignmentIcon from '@mui/icons-material/Assignment';
import '@google/model-viewer';
import DeviceDetector from "device-detector-js";
import LoadingComp from "./LoadComp";

import PrimaryButton from "./Utility/PrimaryButton";
import SecondaryButton from "./Utility/SecondaryButton";

import ScheduleIframeComp from "./IframesComp/ScheduleIframeComp";
import ContactIframeComp from "./IframesComp/ContactIframeComp";
import pushToDataLayer from "./GoogleDataLayer";

import ARjsComp from "./ARJSComp/ARjsComp";

import SlideShowComp from "./SlideShowComp/SlideShowComp";

import SeeDocumenButton from "./Utility/SeeDocumenButton";


import { XrGoodDeviceError, XrGoodPrivacy } from "xrgoods-dev"

import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';


function App(props) {

  let { name } = useParams();


  const appStoreData = {
    timur: {
      glb: "../assets/models/ironview360_timur.glb",
      usdz: "../assets/models/ironview360_timur.usdz",
      audio: "../assets/audios/Ironview360_timur.mp3"
    },
    mike: {
      glb: "../assets/models/ironview360_mike.glb",
      usdz: "../assets/models/ironview360_mike.usdz",
      audio: "../assets/audios/Ironview360_mike.mp3"
    }
  }

  const appData = appStoreData[name] || appStoreData.timur

  console.log({ appData })

  const [url, setURL] = useState()
  const [qrcode, setQrcode] = useState(false)
  const [loading, setLoading] = useState(null)
  const [mode, setMode] = useState(0)
  const [viewerMode, setViewerMode] = useState(false)
  const [video, setVideo] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)


  const [hotspot, setHotspot] = useState(0)

  const [scheduleIframeLink, setScheduleIframeLink] = useState(false)
  const [contactIframeLink, setContactIframeLink] = useState(false)

  const [arjs, setArjs] = useState(false)
  const [slideShow, setSlideShow] = useState(false)
  const [privacyMode, setPrivacyMode] = useState(false)

  const modelViewerRef = useRef()
  const audioRef = useRef()
  const linkRef = useRef()

  const location = window.location.toString();
  const htmlButtonLink = new URL("../assets/ios-buttons/ios-shop-button.html", location).href;
  const usdzLink = `${appData.usdz}#custom=${htmlButtonLink}&customHeight=medium`




  const nextModeHandler = () => {

    if (mode === 2) {
      setMode(3)
      setViewerMode(true)
    } else {
      setMode(mode + 1)
      console.log(mode)
    }
  }

  const playClick = () => {
    // setIsPlaying(!isPlaying)
    const audioDiv = document.querySelector("#avatar-audio")
    if (audioDiv.paused) {
      audioDiv.play()
      setIsPlaying(true)
    } else {
      audioDiv.pause()
      setIsPlaying(false)
    }

    console.dir(audioDiv)

  }


  const startARhandler = () => {
    // props.startAR()

    const deviceDetector = new DeviceDetector();
    const device = deviceDetector.parse(navigator.userAgent);
    const os = device.os.name

    if (os === "iOS") {

      const anchor = linkRef.current
      const href = `${appData.usdz}#custom=${htmlButtonLink}&customHeight=medium`
      anchor.setAttribute("href", href);
      anchor.setAttribute("rel", "ar");
      anchor.appendChild(document.createElement("img"));
      anchor.click();
    } else {
      const canActivateAR = modelViewerRef.current.canActivateAR
      console.log({ canActivateAR })
      if (canActivateAR) {
        modelViewerRef.current.activateAR()
      } else {
        setQrcode(true)
      }

    }

  }

  const startARjsHandler = () => {
    setArjs(true)

  }

  const arModeHandler = () => {
    pushToDataLayer({ 'event': 'btn_clicked', 'clicked_button': 'start_ar_btn' })
    const audioDiv = document.querySelector("#avatar-audio")
    audioDiv.play()
    audioDiv.pause()
    // console.log(props.arMode)

    if (props.arMode === "arjs") {
      startARjsHandler()
    } else {
      startARhandler()
    }

  }




  const refreshPage = () => {
    document.location.reload(true)
  }

  const redireTo = (to) => {
    window.open(to, '_blank')
  }



  useEffect(() => {
    const location = window.location.href
    console.log(location)
    setURL(location)

    modelViewerRef.current.addEventListener("load", () => {
      console.log("load")
      setLoading(undefined)
    });

    modelViewerRef.current.addEventListener("progress", (e) => {
      // console.log("load", e)
      const amount = e.detail.totalProgress
      setLoading(amount)
    });


    modelViewerRef.current.addEventListener("ar-status", (e) => {
      if (e.detail.status === 'object-placed') {

        console.log(audioRef)

        audioRef.current.currentTime = 0
        audioRef.current.play()
      } else {
        audioRef.current.pause()
      }

      if (e.detail.status === 'not-presenting') {

        setSlideShow(true)
      }
      // console.log(e.detail)
    });

    // startARhandler()

    linkRef.current.addEventListener("message", function (event) {
      // window.open("thronetools.com", "_blank")
      // window.open("https://www.ironview360.com/")
      setSlideShow(true)
    }, false);

  }, [])

  return (
    <React.Fragment>

      {arjs && <ARjsComp setArjs={setArjs} appData={appData} setSlideShow={setSlideShow} />}
      {slideShow && <SlideShowComp setPrivacyMode={setPrivacyMode} setContactIframeLink={setContactIframeLink} setScheduleIframeLink={setScheduleIframeLink} onClose={() => setSlideShow(false)} />}
      {privacyMode && <XrGoodPrivacy onClose={() => { setPrivacyMode(null) }} logo="./assets/images/eye.png" company="IronView 360" />}



      {loading != 1 && <LoadingComp loading={loading} />}

      <ScheduleIframeComp link={scheduleIframeLink} onClose={() => { setScheduleIframeLink(null) }} />
      <ContactIframeComp link={contactIframeLink} onClose={() => { setContactIframeLink(null) }} />


      <Box sx={{
        position: 'absolute',
        top: '0',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: 'white',
        transition: 'opacity 1s',
        opacity: '1',
        zIndex: '700',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        fontWeight: 600,
      }}>
        {/* <Box
          component='img'
          src={"./assets/images/the-throne-logo.svg"}
          sx={{
            fontSize: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
        </Box> */}


        <Box
          sx={{
            height: "20px"
          }}
        ></Box>
        <Box
          component='img'
          src={"../assets/images/Black-Logo-horizontal.svg"}
          sx={{
            fontSize: '2rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: "350px",

          }} />
        {/* <Box>{navigator.userAgent}</Box> */}
        <Box sx={{
          height: '45vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: "relative",
          fontWeight: 600,
        }}>
          <model-viewer
            style={{
              width: "100%",
              height: "100%",
              maxWidth: "450px",
              position: "absolute",
              top: "0",
            }}
            ref={modelViewerRef}
            id="model-viewer-demo"
            poster={`../assets/images/poster.png`}
            className="ft-model-viewer"
            src={`${appData.glb}`}
            shadow-intensity="1"
            camera-orbit="-50deg 80deg 15m"
            // max-camera-orbit="auto auto 10m"
            camera-controls
            ar
            auto-rotate
            alt="A 3D model of a webar"
            ar-modes="webxr scene-viewer quick-look"
            autoplay
            loading="eager"
            ios-src={usdzLink}
          >
            <div slot="progress-bar"></div>

            <Button slot="ar-button"
              variant="outlined"
              sx={{
                display: "none",
                borderRadius: "200px",
                position: "absolute",
                top: "120px",
                right: "20px",
                gap: "10px",
                color: "#14c7b9",
                backgroundColor: "white",
                borderColor: "#14c7b9",
              }}
            >
            </Button>

            <SeeDocumenButton onClick={() => { setSlideShow(true) }} />

            <IconButton aria-label="delete"
              onClick={playClick}
              sx={{
                color: "#FD8B1E",
                width: '40px',
                height: '40px',
                position: "absolute",
                bottom: "10px",
                left: "10px"
              }}
            >
              {isPlaying ? <VolumeDownIcon /> : <VolumeOffIcon />}

            </IconButton>

          </model-viewer>
        </Box>


        <Box
          sx={{
            display: "flex",
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: "15px",
            width: "100%"
          }}
        >
          {/* <PrimaryButton
            onClick={() => {
              redireTo('https://www.thronetools.com')
            }}
          >
            Purchase
          </PrimaryButton> */}

          <PrimaryButton
            onClick={arModeHandler}
          >
            <ViewInArIcon
            />
            Launch experience
          </PrimaryButton>

          <SecondaryButton
            onClick={() => {
              pushToDataLayer({ 'event': 'btn_clicked', 'clicked_button': 'schedule_btn' })
              setScheduleIframeLink(true)
              // redireTo()
            }}
          >
            <CalendarMonthOutlinedIcon
              sx={{
                color: "#FD8B1E",
              }}
            />
            Schedule
          </SecondaryButton>

          <SecondaryButton
            onClick={() => {
              pushToDataLayer({ 'event': 'btn_clicked', 'clicked_button': 'contact_btn' })
              setContactIframeLink(true)
              // redireTo('https://www.ironview360.com/contact/')
            }}
          >
            <MailIcon
              sx={{
                color: "#FD8B1E",
              }}
            />
            Contact
          </SecondaryButton>

          <Typography
            onClick={() => {
              // redireTo("https://xrgoods.co/privacy-policy")
              setPrivacyMode(true)
            }}
            sx={{
              color: "black",
            }}
          >Privacy Policy</Typography>
          <Typography
            sx={{
              color: "black",
              cursor: "pointer"
            }}
          >{AppVersion.version}</Typography>
          <Box
            sx={{
              height: "20px"
            }}
          ></Box>
        </Box>

      </Box>


      {qrcode &&
        <XrGoodDeviceError onClose={() => { setQrcode(null) }} />
      }


      <Box sx={{ display: "none" }}
        component="a"
        ref={linkRef}

      ></Box>


      <Box
        sx={{
          // display: "none"
        }}
      >
        <audio id="avatar-audio" ref={audioRef} src={appData.audio} type="audio/x-m4a">
          <code>Lorem ipsum dolor sit amet, consectetur </code>
        </audio>

      </Box>
    </React.Fragment>
  );
}

export default App;

