
import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, styled } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';


const CustomLoading = styled(LinearProgress)((props) => ({
    height: 10,
    width: "70%",
    maxWidth: "250px",
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#EDEDED",
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#FD8B1E',
    },
}));

function LoadingComp(props) {
    




    return (
        <React.Fragment>

            <Box sx={{
                position: 'absolute',
                top: '0',
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap:"40px",
                backgroundColor: 'white',
                transition: 'opacity 1s',
                opacity: '1',
                zIndex: '1400',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                fontWeight: 600,
            }}>
                <Box
                    component='img'
                    src={"../assets/images/Black logo stacked.svg"}
                    sx={{
                        fontSize: '2rem',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: "350px"
                    }}>
                </Box>
                <CustomLoading variant="determinate" value={props.loading * 100} />
            </Box>

        </React.Fragment>
    );
}

export default LoadingComp;


