import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';


class Three360Module {
    constructor(data) {
        this.canvasContainer = data.canvasContainerRef.current
        this.camera = new THREE.PerspectiveCamera(
            70,
            this.canvasContainer.clientWidth / this.canvasContainer.clientHeight,
            0.05,
            50
        );
        this.camera.position.set(0, 0, 1);
        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
            alpha: true,
            preserveDrawingBuffer: true,
        });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(this.canvasContainer.clientWidth, this.canvasContainer.clientHeight);

        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.minDistance = 0.5;
        this.controls.maxDistance = 20;
        this.controls.enableDamping = true
        this.controls.enableZoom = false
        this.controls.autoRotate = true
        this.controls.autoRotateSpeed = 0.7
        this.controls.panSpeed = -1

        this.canvasContainer.appendChild(this.renderer.domElement);


        this.scene = new THREE.Scene();

        this.textureLoader = new THREE.TextureLoader();


        const texture = this.textureLoader.load('../assets/images/slides/360.jpg',)
        const geometry = new THREE.SphereGeometry(2, 32, 16);
        const material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
        const sphere = new THREE.Mesh(geometry, material);
        sphere.scale.x = -1


        this.scene.add(sphere);


        window.addEventListener("resize", onWindowResize.bind(this));
        function onWindowResize() {
            this.camera.aspect = this.canvasContainer.clientWidth / this.canvasContainer.clientHeight;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(this.canvasContainer.clientWidth, this.canvasContainer.clientHeight);
        }

        this.animate()


    }


    animate() {

        requestAnimationFrame(this.animate.bind(this));
        this.controls.update();

        this.renderer.render(this.scene, this.camera);

    }

}

export default Three360Module
