
import React, { useEffect, useRef, useState, useCallback } from "react";
import { Box, Typography, Button, SvgIcon } from "@mui/material";

import Three360Module from "./ThreeModules/Three360Module";

function Three360Comp(props) {

    const canvasContainerRef = useRef();
    const [loaded, setLoaded] = useState(false);


    useEffect(() => {

        new Three360Module({
            canvasContainerRef,
            setLoaded,
        });

    }, [])

    return (

        <Box
            ref={canvasContainerRef}
            sx={{
                cursor:"grab",
                width: '360px',
                height: '360px',
                backgroundColor: '#f4f4f4',
                position: 'relative',
            }}

        >
            <Box
            component='img'
            src="../assets/images/icons/360icon 1.svg"
            sx={{
                position:'absolute',
                top:"0",
                left:"0",
                margin:"10px"
            }}
            >

            </Box>

        </Box>
    )

}

export default Three360Comp
