import * as THREE from 'three';
import { MindARThree } from 'mind-ar/dist/mindar-image-three.prod.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { RoomEnvironment } from "three/examples/jsm/environments/RoomEnvironment.js";


class ThreeJSModule {
    constructor(data) {
        this.mindarThree = new MindARThree({
            container: data.container,
            imageTargetSrc: data.target,
            uiError: "no",
            uiLoading: "no",
            uiScanning: "no",
            maxTrack: 4,
            filterMinCF: 1000,
            filterBeta: 50000,
        });


        this.renderer = this.mindarThree.renderer
        this.scene = this.mindarThree.scene
        this.camera = this.mindarThree.camera
        this.anchor0 = this.mindarThree.addAnchor(0);
        this.anchor1 = this.mindarThree.addAnchor(1);
        this.anchor2 = this.mindarThree.addAnchor(2);
        this.anchor3 = this.mindarThree.addAnchor(3);
        this.setNeedScan = data.setNeedScan
        this.setloading = data.setloading
        this.appData = data.appData
        this.raycaster = new THREE.Raycaster();
        this.pointer = new THREE.Vector2();

        this.audioDiv = document.querySelector("#avatar-audio")
        console.log(this.audioDiv)

        this.animationFrame = null
        this.arScene = new THREE.Group()

        this.mixer = null
        this.action = null
        this.clock = new THREE.Clock();

        this.activeAnimation = false

        this.gltfLoader = new GLTFLoader();



        const map = new THREE.TextureLoader().load('../assets/images/Button.png');
        this.spriteMaterial = new THREE.SpriteMaterial({ map: map, transparent: true, depthWrite: false, depthTest: false });

        // this.spriteMaterial = new THREE.SpriteMaterial({ color: 0xff0000 });

        this.renderer.toneMapping = THREE.LinearToneMapping;
        this.renderer.outputEncoding = THREE.sRGBEncoding;
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap
        this.renderer.toneMappingExposure = 0.95;

        const pmremGenerator = new THREE.PMREMGenerator(this.renderer);

        pmremGenerator.compileEquirectangularShader();
        this.scene.environment = pmremGenerator.fromScene(
            new RoomEnvironment(),
            0.04
        ).texture;




        this.anchor0.onTargetFound = () => {
            console.log("find target 0");
            this.activeAvatar(this.anchor0.group)
        };

        this.anchor0.onTargetLost = () => {
            console.log("lost target");
            this.deactiveAvatar()
        };

        this.anchor1.onTargetFound = () => {
            console.log("find target 1");
            this.activeAvatar(this.anchor1.group)
        };

        this.anchor1.onTargetLost = () => {
            console.log("lost target 2");
            this.deactiveAvatar()
        };
        this.anchor2.onTargetFound = () => {
            console.log("find target");
            this.activeAvatar(this.anchor2.group)
        };

        this.anchor2.onTargetLost = () => {
            console.log("lost target");
            this.deactiveAvatar()
        };
        this.anchor3.onTargetFound = () => {
            console.log("find target 3");
            this.activeAvatar(this.anchor3.group)
        };

        this.anchor3.onTargetLost = () => {
            console.log("lost target");
            this.deactiveAvatar()
        };

        this.init()
    }

    activeAvatar(group) {
        group.add(this.arScene)
        this.setNeedScan(false);
        this.activeAnimation = true
        this.audioDiv.play()
    }
    deactiveAvatar() {
        this.audioDiv.pause()
        this.setNeedScan(true);
        this.activeAnimation = false

    }

    init() {

        this.gltfLoader.load(
            this.appData.glb,
            async function (gltf) {
                gltf.scene.traverse((child) => {
                    if (child instanceof THREE.Mesh) {
                        child.castShadow = true;
                    }
                });
                const model = gltf.scene
                model.scale.set(0.3, 0.3, 0.3)
                model.position.set(-1, -1.4, -0.5)
                this.mixer = new THREE.AnimationMixer(model);
                this.action = this.mixer.clipAction(gltf.animations[0])
                this.action.play()

                const sprite = new THREE.Sprite(this.spriteMaterial);
                sprite.scale.set(0.2, 0.2, 0.2)
                sprite.position.set(0, 0.5, 0)
                this.arScene.add(sprite);
                sprite.visible = false

                this.mixer.addEventListener('finished', () => {
                    sprite.visible = true
                }); // properties of e: type, 

                this.arScene.add(model)

                // console.log(this.physics)


            }.bind(this),
            function (xhr) {
                // console.log("loading legs 3d models");
                const loadingAmount = Math.floor((xhr.loaded / xhr.total) * 100)
                console.log(loadingAmount)
                this.setloading(loadingAmount)
                // this.setLoading(loadingAmount)
            }.bind(this),
            // called when loading has errors
            function (error) {
                console.log("An error happened when loading table legs 3d models");
            }.bind(this)
        );


        this.start();
    }

    animate() {
        // cube.rotateZ(0.01);
        // console.log("rendering")

        const delta = this.clock.getDelta();
        if (this.mixer && this.activeAnimation) this.mixer.update(delta);

        this.animationFrame = requestAnimationFrame(this.animate.bind(this));
        this.renderer.render(this.scene, this.camera);
    }

    stop() {

        cancelAnimationFrame(this.animationFrame)
        this.mindarThree.stop()
        console.log("ar js stoped")
    }

    async start() {
        await this.mindarThree.start();
        this.setloading("done")
        this.setNeedScan(true);
        this.animate();
    };

}

export default ThreeJSModule