
import React, { useEffect, useRef, useState, useCallback } from "react";
import { Box, Typography, Button, SvgIcon } from "@mui/material";

import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

import { styled } from '@mui/system';

function Slider(props) {
    const [inputValue, setInputValue] = useState(50)
    const inputRef = useRef()
    const thumbRef = useRef()
    const inputValueRef = useRef()

    const onInputChange = (e) => {
        // console.log(e.target.value)
        setInputValue(e.target.value)
        props.setSlideWidth(e.target.value)
    }

    useEffect(() => {

    }, [])

    return (
        <React.Fragment>
            <Box sx={{
                width: "300px",
                padding: "40px 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "5px",
            }}>
                <Box
                    // ref={sliderRef}
                    // onPointerMove ={sliderMoveHandler}
                    sx={{
                        width: "100%",
                        alignSelf: "center",
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        borderTop: "3px dotted #FD8B1E",

                    }}>
                    <input
                        step={0.1}
                        ref={inputRef}
                        onChange={onInputChange}
                        style={{
                            // display: 'none',
                            position: "absolute",
                            margin: "0",
                            opacity: "0",
                            width: "100%",
                            zIndex: "200",
                            cursor: 'pointer',
                            // backgroundColor: "red",
                            WebkitAppearance: "none",
                            // WebkitFilter: 'blur(1px) saturate(2)'
                        }}
                        type="range" min={props.min} max={props.max} value={inputValue} ></input>

                    <Box
                        // track
                        // onPointerMove ={sliderMoveHandler}
                        sx={{
                            width: "100%",
                            alignSelf: "center",
                            borderRadius: "10px",
                            position: "relative",
                        }}>

                        <Box
                            ref={thumbRef}
                            sx={{
                                left: `${inputValue * 3}px`,
                                touchAction: "none",
                                cursor: 'pointer',
                                position: 'absolute',
                                top: "0",
                                zIndex: "100",
                                transform: "translate(-50%,-50%)",
                                borderRadius: "50px",
                                display: "flex",
                                alignItems: "center",
                                alignContent: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Box
                            component='img'
                            src="../assets/images/icons/handslide.svg"
                            ></Box>

                        </Box>
                        <Box
                            // range
                            // onPointerMove ={sliderMoveHandler}
                            ref={inputValueRef}
                            sx={{

                                width: `${(inputValue - props.min) / (props.max - props.min) * 100}%`,
                                height: "5px",
                                alignSelf: "center",
                                position: "relative",
                            }}></Box>
                    </Box>



                </Box>

            </Box>
        </React.Fragment>
    )

}

export default Slider
