import { BrowserRouter, Routes, Route } from "react-router-dom";

import App from './components/App';
import { useEffect } from "react";
const RouterComp = () => {
    return (
        <BrowserRouter basename="" >
            <Routes>
                <Route path="/1" element={<App arMode="quicklook" />} />
                <Route path="/2" element={<App arMode="arjs" />} />
                <Route path="/:name/1" element={<App arMode="quicklook" />} />
                <Route path="/:name/2" element={<App arMode="arjs" />} />
                <Route path="*" element={<NoPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default RouterComp;


const NoPage = () => {
    useEffect(() => {
        console.log(window.location)
        window.location.replace(`${window.location.origin}/timur/1`);
    })
    return <div>page redirecting</div>;
};