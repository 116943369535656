
import React, { useState } from "react";
import { Box, Typography, IconButton, Grow } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { keyframes } from '@mui/system';


const blink = keyframes`
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
`;

function SeeDocumenButton(props) {
    const [open, setOpen] = useState(false)



    return (

        <Box
            sx={{
                position: "absolute",
                bottom: "0",
                right: "0",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                justifyContent: 'center',
                margin: "15px",
                gap: "5px"
            }}
        >

            <Grow
                in={true}
            >
                <Typography
                    sx={{
                        display: { xs: "block", sm: open ? "block" : "none", },
                        backgroundColor: "white",
                        padding: "5px",
                        color: "#000",
                        textAlign: "center",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "normal",
                        letterSpacing: "-0.338px",
                        textTransform: "uppercase",
                        cursor: 'pointer',
                        animation:`${blink} 1s infinite alternate ease`,
                    }}
                >
                    See Real Job Sites
                </Typography>

            </Grow>

            <IconButton
                onMouseOver={() => { setOpen(true) }}
                onMouseOut={() => { setOpen(false) }}
                onClick={props.onClick}
                sx={{
                    color: "white",
                    backgroundColor: "#FD8B1E",
                    padding: "1px",
                    '&:hover': {
                        backgroundColor: "#FD8B1E",
                        transform: "scale(1.1)",
                    },
                }}
            >
                <ErrorOutlineIcon />
            </IconButton>

        </Box>
    )

}

export default SeeDocumenButton
